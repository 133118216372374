module.exports = [{
      plugin: require('/zeit/17bc7673/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":740},
    },{
      plugin: require('/zeit/17bc7673/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/17bc7673/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
